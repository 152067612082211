//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  acceptAddNextNode,
  acceptDocumentBill,
  urgingNodeUser,
  acceptNextNode,
  carbonCopyBill,
} from '@/api/modular/fileStream/documentManage'
import { selectPerson } from '@/mixins'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodes from '@/views/fileStream/documentSend/components/nodes.vue'
import processTitle from './processTitle.vue'
export default {
  props: {
    // 節點列表
    documentNodeList: {
      type: Array,
      default() {
        return []
      },
    },
    // 審批狀態
    approvalStatus: {
      type: Number,
      default: 0,
    },
    // 模板名稱
    templateName: {
      type: String,
      default: '',
    },
    // 桃紅狀態 0|1
    nooseStatus: {
      type: [Boolean, Number],
      default: false,
    },
    // 公文類型 3 --- 擬稿
    documentType: {
      type: Number,
      default: 0,
    },
    // 公文接收狀態
    recipientStatus: {
      type: Number,
      default: 0,
    },
    todoType: {
      type: String,
      default: '',
    },
  },
  mixins: [selectPerson],
  components: {
    OpenData,
    selectPersonDialog,
    processTitle,
    nodes,
  },
  computed: {
    isCanSend() {
      const isSelectMainId = !this.mainIds || this.mainIds.length === 0
      const isNeedNoose = this.documentType === 3 && !this.nooseStatus
      return isSelectMainId || isNeedNoose
    },
    documentNodeListCopy() {
      const documentNodeList = this.finnalyDocumentNodeList.slice()
      const cs = this.documentNodeList.find((j) => j.sort === 100)
      const ms = this.documentNodeList.find((j) => j.sort === 99)
      // documentNodeList = documentNodeList.filter(node => node.sort !== -1)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.csr_list = (cs?.nodes || []).map((j) => ({
        corpId: j.corpId,
        [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
        type: this.getDataType(j),
      }))
      this.mainIds = (ms?.nodes || []).map((j) => ({
        corpId: j.corpId,
        [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
        type: this.getDataType(j),
      }))
      return documentNodeList
    },
    finnalyDocumentNodeList() {
      const documentNodeList = this.documentNodeList.slice()
      // documentNodeList.forEach((j) => {
      //   if (![-1].includes(j.sort)) {
      //     this.formatNodeMess(j)
      //   }
      // })
      return documentNodeList
    },
    stepIndex() {
      return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
    },
    approvalFlag() {
      return this.documentNodeListCopy
        .filter((item) => item.sort == 99)[0]
        ?.nodes.filter((item) => item.userId == userId)[0]?.approvalFlag
    },
    allList() {
      let _list = this.list.map((item) => item.val)
      return [..._list, ...this.csr_list]
    },
  },
  data() {
    return {
      modelVisible: false,
      addNodesLoading: false,
      approvalId: '',
      nodeId: '',
      csr_list: [],
      mainIds: [],
      carbonCopying: false,
      list: [
        {
          name: '節點',
          val: [],
        },
      ],
    }
  },

  methods: {
    setNodesMess(evt) {
      const { list } = evt
      this.list = list
    },
    judgeIsShowSelect(stepIndex, documentNodeIndex) {
      const stepObj = this.documentNodeListCopy.find((node) => node.nodeFlag === 0)
      const isAccet = stepObj?.sort === 99
      const isFinaly = [stepIndex, stepIndex + 1].includes(documentNodeIndex)
      return this.todoType === 'dealWith' && isFinaly && isAccet
    },
    judegeIsShowAcceptStatus(stepIndex, documentNodeIndex) {
      const stepObj = this.documentNodeListCopy.find((node) => node.nodeFlag === 0)
      const isAccet = stepObj?.sort === 99
      const isFinaly = [stepIndex, stepIndex + 1].includes(documentNodeIndex)
      console.log(isFinaly, isAccet)
      return isFinaly && isAccet
    },
    getSendKey(sort) {
      return sort == 99 ? 'mainIds' : 'csr_list'
    },
    getColor(sort, documentNodeIndex, stepIndex) {
      const isSort = [99, 100].includes(sort)
      const isLast = this.documentNodeListCopy[stepIndex]?.sort === 99
      const isArri = [stepIndex, stepIndex + 1].includes(documentNodeIndex)
      if (isLast && isSort && isArri) return '#e9923d'
      return ''
    },
    handleCancel() {
      this.modelVisible = false
    },
    formatNodeMess(node) {
      const arr = []
      const getApprovalTime = (deptUsers) => {
        if (deptUsers.some((l) => l.approvalFlag === 0)) return null
        const times = deptUsers.map((user) => {
          const timeStr = user.approvalTime.replace(/-/g, '/')
          const time = new Date(timeStr).getTime()
          return time
        })
        return this.formatApprovalTime(Math.max(times))
      }
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          arr.push({
            deptId: dep.deptId,
            deptUsers: dep.deptUsers,
            corpId: dep.deptUsers[0].corpId,
            approvalTime: getApprovalTime(dep.deptUsers),
            status: Number(!dep.deptUsers.some((l) => l.status === 0)),
            approvalFlag: Number(!dep.deptUsers.some((l) => l.approvalFlag === 0)),
          })
        })
      }
      let array = []
      array = array.concat(
        [...node.nodes, ...arr].filter((j) => j.approvalFlag === 1),
        [...node.nodes, ...arr].filter((j) => j.approvalFlag !== 1)
      )
      node.nodes = array
    },
    getDataType(node) {
      return node.deptId ? 'departmentName' : 'userName'
    },
    addNodes({ id }, nodeId) {
      this.approvalId = id
      this.nodeId = nodeId
      this.modelVisible = true
    },
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.$route.query.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('接收成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    carbonCopy() {
      if (this.carbonCopying) return
      this.carbonCopying = true
      const arr = this.documentNodeListCopy.filter((j) => [99, 100].includes(j.sort))
      const getUserList = (sort) => {
        return this[sort === 99 ? 'mainIds' : 'csr_list'].map(({ userId, corpId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId,
        }))
      }
      const data = arr.map(({ id, sort }) => ({
        nodeId: id,
        sort,
        userList: getUserList(sort),
      }))
      carbonCopyBill(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('下發成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.carbonCopying = false
        })
    },
    getIdKey(type) {
      return type === 'departmentName' ? 'departmentId' : 'userId'
    },
    handleOk() {
      if (this.list.some((j) => !j.val || j.val.length === 0)) {
        this.$message.warning('存在節點沒有選擇人員！')
        return
      }
      if (this.addNodesLoading) return
      this.addNodesLoading = true
      const documentNodeParams = this.list.map((el, index) => ({
        billId: this.$route.query.id,
        sort: index,
        name: el.name,
        countersign: el.countersign,
        approvals: el.val.map(({ corpId, userId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId: corpId,
        })),
      }))
      acceptAddNextNode({
        documentNodeParams,
        approvalId: this.approvalId,
        nodeId: this.nodeId,
      })
        .then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.$nextTick(() => {
              this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
              // this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.modelVisible = false
          this.addNodesLoading = false
        })
    },
    delDom(i) {
      this.list.splice(i, 1)
    },
    dealNext(id, nodeId) {
      acceptNextNode({
        billId: this.$route.query.id,
        nodeId: id,
        approvalId: nodeId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('流程確認成功！')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editDom() {
      const dom = arguments[0].target.parentElement.getElementsByClassName('input-dom')[0]
      dom.removeAttribute('readOnly')
      dom.classList.remove('input-dom-edit')
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    formatApprovalStatus(approvalFlag, sort, status) {
      if (sort === 99) {
        return approvalFlag ? '已接收' : '待接收'
      }
      if (sort === 100) {
        return approvalFlag ? '已通知' : '待通知'
      }
      if (approvalFlag === 0) {
        return '待審核'
      } else if (approvalFlag === 3) {
        return '同級已審'
      } else if (status === 1) {
        return '審核通過'
      } else {
        return '駁回'
      }
    },
    // 催辦 王念修改 催辦
    urgingSubmit(node, billId) {
      urgingNodeUser({
        documentBillId: billId,
        userId: node.userId,
        corpId: node.corpId,
        deptId: node.deptId,
        status: node.status
      })
      .then((res) => {
         if (res.code === 200) {
            this.$message.success('已催辦對應審批人')
          }
      })
      .catch((err) => {
          console.log(err)
        })
    },
  },
}
